import React, { useEffect, useState } from 'react';
import HeaderImage from '../../components/Account/HeaderImage';
const LoaderScreen = (props) => {

  return (
  <div id='loaderScren'>
    <div className='headerImg'>
      <HeaderImage />
    </div>
    <div className="vertically-center10 loader-yellow">
      <div className='gif'>
        <img src='/images/loader.gif' alt='loader'/>
      </div>
      <div className='desc'>
        <p className='title text-center'>{
          props?.checkingconnection == 2 ? "Checking for Secured connection" :
          props?.validateUserId == 2 ? "Validate User ID" : 
          props?.validateAuth0 == 2 ? "Validate Auth0" :
          props?.checkingmultirole === 2 ? "Checking if any Multi-Role" : "Validated successfully Signing-in"
        }</p>
        <p className='sub-title text-center'>Please give us a second as we process your details.</p>
      </div>
    </div>
  </div>
  )
}
export default LoaderScreen