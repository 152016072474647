import React from "react"
import Footer from "./Footer";
import Header from "./HeaderImage";


const Invalid = ({ message, type }) => {
    return (

        <div className="linkExpired">
            <img src="/icons/linkExpired.svg"/>
            <p className="title text-center mt-0" style={{color:"#333333", fontSize:"22px"}}>{message}!! </p>
            <p className="sub-title text-center">The link you are trying to access {type === "expired" ? "has expired or is" : "is invalid or"} no longer available.</p>
        </div>





        // <div>
        //     <div className="row" >
        //         <div className='col-sm-4 bg-white m-auto rounded dased-border p-3'>
        //         <Header />
        //             <div className="container-fluid box6" >
        //                 <div className="d-flex mt-5 justify-content-center align-items-center">
        //                     <div className='card w-100  py-3' id='cardbox1'>
        //                         {/* <h1 className='text-center mt-2 fs-1 mb-4'>Reset Password</h1> */}
        //                         <p className=" mt-3 pt-3 p-3 mb-3 bg-danger text-white align-items-center">{message}!! </p>
        //                     </div>
        //                 </div>
        //             </div>
        //         </div>
        //         <div className="row p-0 m-0">
        //             <div className="col-sm-4 m-auto p-0 m-0">
        //                 <Footer />
        //             </div>
        //         </div>
        //     </div>
        // </div>
    )
}
export default Invalid;