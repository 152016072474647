export const otpMedium = {
    otpSendMedium: [{
        sms: 1
    }]
};

export const errorMsg = {
    firstName: {
        invalid: "Please enter valid First Name Allowed",
        empty: "First name is required",
    },
    lastName: {
        invalid: "Please enter valid Last Name Allowed",
        empty: "Last name is required",
    },
    phoneNumber: {
        invalid: "Enter the valid Phone Nummber",
        empty: "Please enter the Phone Number"
    },
    emailId: {
        invalid: "Please enter a valid email address",
        empty: "Please enter email address",
        already: "This email is already associated with other account or partner",
    },
    password: {
        invalid: "Your password does not meet our security criteria",
        empty: "Please enter password"
    },
    confirmPassword: {
        invalid: "Your password does not match",
        empty: "Please enter confirm password"
    },
    otp: {
        invalid: "Please enter a valid OTP",
        empty: "Please enter OTP"
    },
    somethingErr: "Something went wrong. Try again later."
}

export const successMsg = {
    forgotLinkSent: 'A reset password link has been sent to your registered email address. Click the link to create a new password.',
    otpSent: 'OTP has been sent to your email address and phone number.',
}

export const hideSideImageForUrlPaths = ["/account/redirect", "/account/unauthorizedaccess", "/account/activation", '/account/invalid'];