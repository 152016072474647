import React, { useCallback, useEffect, useState, useContext } from 'react';
import Header from "../../components/Account/HeaderImage";
import Footer from "../../components/Account/Footer";
import { useNavigate, useSearchParams } from 'react-router-dom';
import { auth0Config } from '../../components/auth0';
// import auth0Cred from '../auth0Cred.json'
import { auth0Cred } from '../auth0Cred';
import konsole from '../../components/control/Konsole';
import { $CommonServiceFn } from '../../components/network/Service';
import { $Service_Url } from '../../components/network/UrlPath';
import { UserContext } from '../../App';

const SignOut = () => {
    const navigate =  useNavigate();
    const [searchParams] = useSearchParams();
    const [isMounted, setIsMounted ] =useState(true);
    const success = searchParams.get('success');
    const appState = searchParams.get('appState');
    const loggenInId = searchParams.get('loggenInId');
    const roleId = searchParams.get('roleId');
    const userId = searchParams.get('userId');
    const { setLoader } = useContext(UserContext);

    useEffect(()=>{
        navigate('/Account/SignIn')
    },[])

    useEffect(()=>{
        if(isMounted == true){
            konsole.log("success", success);
            konsole.log("appstate", appState);
            konsole.log("logg", loggenInId);
            konsole.log("roleId", roleId);
            konsole.log("userID", userId);
            if (success == null) {
                handleLogout();
            }
            else if (success == "true" && appState !== null && roleId !== null && userId !== null && loggenInId !== null) {
                handleLogoutApi(userId, appState, loggenInId, roleId);
                konsole.log('call api')
            }
            setIsMounted(false)
        }

    },[])

    const handleLogout = useCallback(() => {
        auth0Config().logout({
            returnTo: window.location.origin + "/Account/signout?success=true",
            // clientID: auth0Cred.clientId
        }, (err, authResult) => {
            if (err) {
                konsole.warn("Error", err);
                return;
            }
            if (authResult) {
                konsole.log('authResp', authResult);
            }
        });

    },[])

    const handleLogoutApi = useCallback((userId, appState, loggedInUserId, roleId) => {
        setLoader(true);
            const params = `${userId}/${appState}/${loggedInUserId}/${roleId}`
            $CommonServiceFn.InvokeCommonApi("GET", $Service_Url.postLogUserout + params, '', (response, errorData) => {
                setLoader(true);
                if (response) {
                    setLoader(false);
                    konsole.log('successsuccess', response);
                    handleLogout();
                }
                else if (errorData) {
                    setLoader(false);
                    konsole.log("err", errorData);
                }
            })
    }, [userId, appState, loggenInId, roleId])

    return (
        <div className="container-fluid row d-flex flex-column m-0">
            <div className="col-sm-12 col-md-8 col-lg-4 bg-white p-5 m-auto dased-border mt-5">
                <Header />
                <h1 className='text-center mb-3 pt-5'>Sign Out</h1>
                <h4 className='text-center mb-5' style={{ fontWeight: "600" }}>Logged Out Successfully!</h4>
                <div className=' d-flex my-3 flex-column justify-content-center align-items-center'>
                    <p className='fs-5'>Click below to Sign In again. </p>
                    <div className='pt-2' style={{ fontWeight: "600" }}>
                        <button className='rounded theme-btn' onClick={() => navigate('/Account/SignIn')}> Sign In</button>
                    </div>
                </div>
            </div>
            <div className='col-sm-12 col-md-8 col-lg-4 m-auto p-0'><Footer /></div>
        </div>
    );

}

export default SignOut;
