import { Link, Navigate } from "react-router-dom";
import SignUp from "./Signup";
import SignIn from "./Login";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import Countdown from 'react-countdown';
import HeaderImage from "../../components/Account/HeaderImage";


const Page = () => {
    const navigate = useNavigate();

    const redirect = () => {
        navigate("/account/signin");
    }

    const renderer = ({ hours, minutes, seconds, completed }) => {
        return <span style={{fontSize:"20px"}}>0{minutes}:0{seconds}</span>;
    };
        
    return (
        <div id='unAuthorized'>
            <div className='headerImg'>
                <HeaderImage />
            </div>
            <div className="vertically-center11">
                <div className="wentWrong"><img src="/icons/wentWrong.svg"/></div>
                <p className='title text-center mt-0'>Oops... Something went wrong !!! </p>
                <p className='sub-title text-center'> You will be redirected to the Login page shortly</p>
                <p className="countDown"><Countdown date={Date.now() + 5000} renderer={renderer} onComplete={redirect} /></p>
            </div>
        </div>
    );
};

export default Page;
