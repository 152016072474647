import React, { useState, useEffect, useContext } from 'react';
import { useForm } from 'react-hook-form';
import Header from "../../components/Account/HeaderImage";
import Footer from "../../components/Account/Footer";
import { $CommonServiceFn } from '../../components/network/Service';
import { $Service_Url, ipUrl } from '../../components/network/UrlPath';
import konsole from '../../components/control/Konsole';
import Success from '../../components/Account/Success';
import { NavLink, useNavigate } from 'react-router-dom';
import { UserContext } from '../../App';
import commonLib from '../../components/control/commonLib';
import HeaderImage from '../../components/Account/HeaderImage';
import CommonInputContainer from '../../components/CommonInputContainer';
import { errorMsg, successMsg } from '../../components/control/Constant';

export default function Forgot() {
  const navigate = useNavigate();
  const [subtenantId,setSubtenantId] = useState(0)
  const [email, setEmail] = useState({

    userName: "",
    emailID: "",
    requestedIP: "",
    commMedium: "",
    commSendOn: ""

  });
  const [countryipCode, setCountryIpCode] = useState('');
  const [CountryGeoIp, setCountryGeoIp] = useState('');
  const { setLoader, setWarning } = useContext(UserContext);
  let subtenantDetails = sessionStorage?.getItem('subtenantDetails')
  useEffect(()=>{
  let subtenantId = commonLib.getQueryFromUrl("subtenantId");
  if (subtenantId) {
    setSubtenantId(subtenantId);
  }
  else if (subtenantDetails !== null) {
    setSubtenantId(JSON.parse(subtenantDetails).subtenantId)
  }
  else {
    setSubtenantId(null);
  }
  },[subtenantDetails])

  useEffect(() => {
    // getCountryId();
    // getCountryipCode();


  }, [])
  // const getCountryId = () => {
  //   setLoader(true);
  //   $CommonServiceFn.InvokeCommonApi("GET", $Service_Url.getCountryipCode, "", (response) => {
  //     if (response) {
  //       setLoader(false);
  //       konsole.log("sdkjvbkj", response)
  //       setCountryIpCode(response.data.data)
  //     }
  //   })
  // }
  // const getCountryipCode = async () => {
  //   setLoader(true);
  //   let data = await fetch(ipUrl);
  //   let parsedData = await data.json();
  //   konsole.log("sapp", parsedData);
  //   setLoader(false);
  //   setCountryGeoIp(parsedData.IPv4);
  // }

  // Success Page...........
  const [getEmail, setGetEmail] = useState("")


  // Forgot Page.........
  const [correction, setCorrection] = useState(false)
  
  const [isInActiveError, setIsInActiveError] = useState(false)



  const handleChange = (e) => {
    e.target.value = e.target.value.trimStart();
  }


  const { register, setError, handleSubmit, watch, formState: { errors } } = useForm();
  const [achived, setAchived] = useState()

  const onSubmit = data => {
    setLoader(true);
    konsole.log("data", data);
    const inputData = data;
    const input = {
      userName: inputData.email,
      emailID: inputData.email,
      requestedIP: CountryGeoIp || "::1",
      commMedium: "Email",
      commSendOn: inputData.email
    }
    setLoader(false);
    konsole.log("input", input)
    setGetEmail(input.emailID);
    if (data.email !== undefined) {
      setLoader(true);
      $CommonServiceFn.InvokeCommonApi("POST", $Service_Url.postForgotPassword, input, (response, errData) => {
        setLoader(false);
        if (response) {
          if (response.data.data == true) {
            setAchived(true)
            konsole.log(response);
            setWarning("success", successMsg.forgotLinkSent);
          }
        }
        else {
          if (errData?.data?.data == false) {
            // setLoader(false);
            setCorrection(true)
            konsole.log(errData)
            if(errData.data.messages[0] === "USER_NOT_ACTIVE") setIsInActiveError(true);
            else setIsInActiveError(false);
          } else {
            konsole.log("dbvkb", errData)
            setWarning('warning', errorMsg.somethingErr);
          }
        }
      });
    }
  }
  const removeCorrection = () => {
    if(correction){
      setCorrection(!correction);
    }

    if(errors.email?.message) setError("email", "");
  }
  const handleRedirect = () => {
    if (subtenantId == 3) {
      navigate(`/Account/startfreetrial?subtenantId=${subtenantId}`)
    } else {
      navigate('/Account/Signup')
    }
  }
  const handleSelect=()=>{
    setLoader(true);
    const params = `?emailId=${getEmail}`
    $CommonServiceFn.InvokeCommonApi("GET",$Service_Url.getLoginuserDetail + params ,'',(res, err) => {
      if (res) {
        setLoader(false);
        let response = res?.data[0]
        let newJson = {
          "userRegstrtnId": response?.id,
          "userId": response?.userId,
          "signUpPlatform":response?.signUpPlatform,
          "createdBy": response?.userId,
          "clientIPAddress": "::1"
        }
        setLoader(true);
        $CommonServiceFn.InvokeCommonApi("POST",$Service_Url.getUserActivationLink,newJson,(res, err) => {
          if (res) {
            let respons = res?.data?.data
            window.location.replace(respons?.activationURL)
    
          } else {
            setLoader(false);
            konsole.log("getUserActivationLink", err)
          }
        })  
        

      } else {
        setLoader(false);
        konsole.log("getUserActivationLink", err)
      }
    })
  }
  
  return (

    <>
      <div id='forgetPassword'>
      <div className='headerImg'>
        <HeaderImage/>
      </div>

      {achived ? <Success sendEmail={getEmail}/> 
      : 
      <form onSubmit={handleSubmit(onSubmit)} className="vertically-center4">
      <div className='mssgeMainDiv'>
      <img src="/icons/key.svg"/>
      </div>
        <p className='title text-center'>Forgot password?</p>
        <p className='sub-title text-center'>No worries, we'll send you reset instructions.</p>

        <CommonInputContainer
          label="Email"
          error={errors?.email?.message ? errors.email : undefined}
        >
          <input className={`w-100 ${errors?.email?.message ? 'input-error' : ''}`}
            type='email' placeholder='Enter your email' 
            {...register('email', {
              required: 'Please enter email address',
              pattern: {
                value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                message: 'Please enter a valid email address',
              },
            })}
            onChange={removeCorrection}
            onInput={handleChange}
          />
        </CommonInputContainer>

        {correction &&
                  (<p className='attention'>{isInActiveError ? "Please verify your email address to continue. " : "No account found with this email address. "}
                    {/* <br /> */}
                    {isInActiveError ? <>
                    <a href='#' className="aTagElement" onClick={() => handleSelect()}> Verify here.</a>
                    {/* <span className='createNAccount cursor-pointer' onClick={() => handleSelect()}> activate your account.</span> */}
                  </> : <>
                    <a href='#' className="aTagElement" onClick={() => handleRedirect()}> create a new account.</a>
                  </>}
                    </p>)
                }

        <div className="label-input">
          <button type="submit">Reset password</button>
        </div>

        <NavLink className="backsignUp" to="/Account/SignIn">
           <img src='/icons/backImgEmail.svg'/>
          <p>Back to log in</p>
        </NavLink>
      </form>}
    </div>
    </>     
  );
};