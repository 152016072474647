import { $CommonServiceFn } from '../network/Service';
import konsole from '../control/Konsole';
{/*   ----***--------***---- post api call with async ----***--------***---- */ }

export const postApiCall = (method, url, jsonObj) => {
  return new Promise((resolve, reject) => {
    $CommonServiceFn.InvokeCommonApi(method, url, jsonObj, (res, err) => {
      if (res) {
        konsole.log('res of saving', url, res);
        resolve(res)
      } else {
        konsole.log('res in saving', url, err);
        resolve('err')
      }
    })
  })
}

  // /*   ----***--------***---- get api call with async ----***--------***---- */ }

export const getApiCall = (method, url, setState) => {
  return new Promise((resolve, reject) => {
    $CommonServiceFn.InvokeCommonApi(method, url, '', (res, err) => {
      if (res) {
        konsole.log('res of fetching', url, res, 'data');
        if (setState) {
          setState(res?.data?.data);
        }
        resolve(res?.data?.data);
      } else {
        konsole.log('res in fetching', url, err);
        if (setState) {
          setState([]);
        }
        resolve('err');
      }
    });
  });
}

export const capitalizeAllLetters =(string)=> {
  if (typeof string !== 'string') return string; 
  return string.toUpperCase();
}
