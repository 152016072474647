


const Footer = () => {
    return (
        <div className="my-1 brand-aging-options-footer pt-2">
            <div className="brand-aging-options display-4 d-flex justify-content-center">
                <p className="h5">Powered By</p>
            </div>
            <div className="d-flex justify-content-center">
                <img src="../images/logo-footer.png" alt="brandAgingOptions" />
            </div>
        </div>


    );
}

export default Footer;