import React, {useState, useEffect} from "react";

const CommonInputContainer = ({ 
    children,
    classNameProps,
    label,
    error
  }) => {
  
  const [showError, setshowError] = useState(false);

  useEffect(() => {
    setshowError(false)
  }, [error])

  return (
  <div className={`label-input ${classNameProps ? classNameProps : ''}`}>
    <label>{label}</label>
    <div className='input-container'>
      {children}
      {error && <img 
        src='/icons/validationImg.svg' 
        className='validation-icon img-fluid cursor-pointer mx-1'  
        alt='' 
        // onClick={() => setshowError(!showError)}
        // onMouseOver={()=> setshowError(true)}
        // onMouseOut={()=> setshowError(false)}
      />}
    </div>
    {(error?.message) ? <p className='attention'>{error?.message}</p> : ""}
    {/* {(error?.message && showError)? <p className='attention'>{error?.message}</p> : ""} */}
  </div>
  )
}

export default CommonInputContainer;