import Config from '../../config.json'

const demo = Config.DEMO;
// export const BASE_URL = (demo)? "https://aointakeuatapi.azurewebsites.net": "https://aointakeapi.azurewebsites.net";
export const BASE_URL = (demo)? "https://aointakeuatapi.agingoptions.com": "https://aointakeapi.azurewebsites.net";

// export const AoIntakeBaseUrl = (demo)?"https://aointakeformuat.azurewebsites.net/":"https://aointakeform.azurewebsites.net/";
// export const LpoLiteBaseUrl =  (demo)?"https://lpoliteuat.azurewebsites.net/":"https://lpolite.azurewebsites.net/";
// export const EventBaseUrl =    (demo)?"https://eventuat.azurewebsites.net/":"https://eventdev.azurewebsites.net/";
// export const CounterBaseUrl =  (demo)?"https://aoeventcounteruat.azurewebsites.net/": "https://aoeventcounter.azurewebsites.net/";
// export const CounterMobileBaseUrl = (demo)?"https://aoeventcountermobileuat.azurewebsites.net/": "https://aoeventcountermobile.azurewebsites.net/";
// export const AoAdminBaseUrl =       (demo)?'https://aoadminuat.azurewebsites.net/': 'https://aoadmindev.azurewebsites.net/';;
// export const AoPartnerBaseUrl =     (demo)?"https://aopartneruat.azurewebsites.net/": "https://aopartner.azurewebsites.net/";;
export const AoIntakeBaseUrl = (demo)?"https://aointakeformuat.agingoptions.com/":"https://member.intake.agingoptions.com/";
export const LpoLiteBaseUrl =  (demo)?"https://lpoliteuat.agingoptions.com/":"https://member.LPO.agingoptions.com/";
export const EventBaseUrl =    (demo)?"https://eventuat.agingoptions.com/":"https://events.agingoptions.com/";
export const CounterBaseUrl =  (demo)?"https://aoeventcounteruat.agingoptions.com/": "https://Event.counter.agingoptions.com/";
export const CounterMobileBaseUrl = (demo)?"https://aoeventcountermobileuat.agingoptions.com/": "https://event.mcounter.agingoptions.com/";
export const AoAdminBaseUrl =       (demo)?'https://Aoadminuat.agingoptions.com/': 'https://AOAdmin.agingoptions.com/';;
export const AoPartnerBaseUrl =     (demo)?"https://aopartneruat.agingoptions.com/": "https://partner.admin.agingoptions.com/";;
export const IntakeEntryBaseUrl =    (demo)?"https://intakeentryformuat.azurewebsites.net/": "https://intakeentryform.azurewebsites.net/";;
export const AoAcaddemyUrl =        "https://staging10.agingoptionsacademy.com/";
export const RegisterAoAcademyUrl = "https://staging10.agingoptionsacademy.com/wp-login.php?action=register";


let $Service_Url = {
  getCountryCode: BASE_URL + '/api/Country/GetRegistrationEnableCountry',
  postOtpData: BASE_URL + '/api/OTP/Validate-OTP-For-NewUser',
  postResendOtp: BASE_URL + '/api/OTP/ReSend-OTP-To-User',
  postSendOtp: BASE_URL + '/api/OTP/Send-OTP-To-User',
  postForgotPassword: BASE_URL + '/api/User/SendEmailForForgotPassword',
  getAuthApiSettings: BASE_URL + '/api/Settings/authSettings',
  postAuth0UserLogin: BASE_URL + '/api/User/LogUserLogin',
  getUserDetailsByUSerID: BASE_URL + '/api/User/GetUserDetailsbyTokenKey/',
  // postUpdateForgotPassword: BASE_URL + '/api/User/UpdateForgotPassword',
  postUpdateForgotPassword: BASE_URL + '/api/2/User/UpdateForgotPassword',
  postLogUserout: BASE_URL + '/api/User/LogoutUser/',
  getSubtenantDetailPath: BASE_URL + '/api/Subtenant/get-subtenant-details',
  validateUserByLink: BASE_URL + '/api/UserActivation/ValidateLink',
  postActiveUser: BASE_URL + '/api/UserActivation/ActivateUser',
  postsignupcreateUser:BASE_URL+'/api/User/CreateUser',
  checkLoginUserExist: BASE_URL + '/api/user/GetUserStatusByUserName/',
  createAcademyUser: BASE_URL + '/api/User/CreateAcademyUser',
  getIsAuthUser:BASE_URL+ '/api/UserAuth/IsAuthUser/',
  getUserDetailsByEmailId:BASE_URL+'/api/Account/GetUserDetailByEmailId',
  getUserActivationLink:BASE_URL+'/api/UserActivation/GetUserActivationLink',
  getLoginuserDetail:BASE_URL+'/api/Account/GetUserDetailByEmailId',
  getUserActivationLink:BASE_URL+'/api/UserActivation/GetUserActivationLink',
  getsubjectForFormLabelId:  BASE_URL + "/api/Subject/get-subject-for-form-label-id",
  getSubjectResponse: BASE_URL + "/api/Subject/get-user-subject/",
  putusersubjectdata:BASE_URL+ '/api/Subject/update-user-subject-data',
  postaddusersubjectdata: BASE_URL + "/api/Subject/add-user-subject-data",
  customerFeedback :BASE_URL+ '/api/CustomerFeedback/GetCustomerFeedback',

  getSubjectbyquestionId:BASE_URL+'/api/Subject/get-response-for-questionid/'
  
};

export let ipUrl = "https://geolocation-db.com/json/";

export { $Service_Url };