import auth0 from 'auth0-js'
import Config from '../config.json'


// import auth0Cred from '../pages/auth0Cred.json';
import { auth0Cred, auth0CredDev,lagecyauth0Cred } from '../pages/auth0Cred';
import axios from 'axios';

function auth0Config() {
    const demo = Config.DEMO;
    const auth0CredLocal =  (demo)? auth0CredDev: auth0Cred; 
    let auth0Config = new auth0.WebAuth({
        domain: auth0CredLocal.domain,
        clientID: auth0CredLocal.clientId,
        responseType: auth0CredLocal.responseType,
        redirectUri: window.location.origin + "/Account/Redirect",
        scope: auth0CredLocal.scope
    });

    return auth0Config;
}

function auth0Config_Legacy() {

    let auth0Config_Legacy = new auth0.WebAuth({
        domain:lagecyauth0Cred.domain,
        clientID:lagecyauth0Cred?.clientID,
        responseType:lagecyauth0Cred?.responseType,
        redirectUri:lagecyauth0Cred?.redirectUri,
        scope:lagecyauth0Cred?.scope
    })

    return auth0Config_Legacy;
}

async function verifyPassword ( username, password ) {
    try {
        const auth0CredLocal = (Config?.DEMO) ? auth0CredDev : auth0Cred;
        const response = await axios.post(`https://${auth0CredLocal.domain}/oauth/token`, new URLSearchParams({
            grant_type: 'password',
            password: password,
            username: username,
            audience: auth0CredLocal.apiAudience,
            scope: auth0CredLocal.scope,
            client_id: auth0CredLocal.clientId,
            client_secret: auth0CredLocal.clientSecret,
            responseType: auth0CredLocal.responseType,
            responseMode: auth0CredLocal.responseMode
        }), {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        });
        return true;
    } catch (error) {
        return false;
    }
}

export { auth0Config, auth0Config_Legacy, verifyPassword}
