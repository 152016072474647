import { BrowserRouter,Routes,Route, Navigate, useLocation } from 'react-router-dom';
import './styles/global.scss';


import Signup from './pages/Account/Signup';
import Login from './pages/Account/Login';
import Signout from './pages/Account/signout';
import ForgetPassword from './pages/Account/ForgotPassword';
import Congratulations from './pages/Account/Congratulation';
import Httperror from './pages/Account/Httperror';
import Otp from './pages/Account/Otp';
import Changepassword from './pages/Account/ChangePassword';
import React,{ useEffect, useRef, useState } from 'react';
import commonLib from './components/control/commonLib';
import SuccessResetPassword from './pages/Account/successResetPassword';
import Redirect from './pages/Account/Redirect';
import UnAuthorized from './pages/Account/UnAuthorized'
import Activation from './pages/Account/Activation';
import toast ,{Toaster} from 'react-hot-toast';
import StartFreeTrial from './pages/Account/StartFreeTrial';
import { Layout } from './components/Layout';
import SelectRoles from './components/Account/SelectRoles';
import CustomAlert from './components/CustomAlert';
import { hideSideImageForUrlPaths } from './components/control/Constant';
import InvalidPage from './pages/Account/InvalidPage';

export const UserContext = React.createContext({
  loader: false,
});

function App() {
  const [loader, setLoader] = useState(false);
  const [curMainImageNum, setcurMainImageNum] = useState();
  const [commonProp, setcommonProp] = useState(false);
  const [loaderText, setLoaderText] = useState('');
  const warningRef = useRef();

  useEffect(()=>{
    if(loaderText !== null && loaderText !== ''  && loaderText !== undefined && loader == true){
      toast.success(`${loaderText}`);
    }
  },[loaderText])

  const setWarning = ( type, description ) => {
    warningRef?.current?.setAlert?.(type, description);
  }

  return (
  <UserContext.Provider value={{ loader, setLoader, setLoaderText, setcurMainImageNum, commonProp, setcommonProp, setWarning}}>
    <Toaster  reverseOrder={false} />

    {/* universal loader */}
    <div className="overlay position-relative">
      {loader && 
        <div className="d-flex flex-column align-items-center justify-content-center position-absolute overLayLoader">
          <div className="spinner-border" role="status" aria-hidden="true"></div>
        </div>
      }
    
    <CustomAlert ref={warningRef} />
      
      <BrowserRouter>
    <Layout curMainImageNum={curMainImageNum} >
        <Routes>
          <Route path="/account/signup" exact element={<Signup />} />
          <Route path="/account/signin" exact element={<Login />} />
         
          <Route path="/account/resetPassword" exact element={<Changepassword />} />
          <Route path="/account/setPassword" exact element={<Changepassword />} />
          <Route path="/account/ForgetPassword" exact element={<ForgetPassword />} />
          <Route path="/account/ErrorPage" exact element={<Httperror />} />
          <Route path="/account/verifyOtp" exact element={<Otp />} />
          <Route path="/account/Signout" exact element={<Signout />} />
          <Route path="/account/congratulation" exact element={<Congratulations />} />
          <Route path="/account/UnAuthorizedAccess" exact element={<UnAuthorized />} />
          <Route path="/account/Redirect" exact element={<Redirect />} />
          <Route path="/account/SelectRoles" exact element={<SelectRoles />} />
          <Route path="/account/Activation" exact element={<Activation />} />
          <Route path="/account/StartFreeTrial" exact element={<Signup />} />
          <Route path="/account/SuccessResetPassword" exact element={<SuccessResetPassword />} />
          <Route path="/account/Invalid" exact element={<InvalidPage />} />
          <Route
            path="/"
            element={<Navigate to="/account/Signin " replace />}
          />
          <Route path="*" exact element={<Httperror/>} /> 
        </Routes>
    </Layout>
      </BrowserRouter>
    </div>
  </UserContext.Provider>
  );
}

export default App;