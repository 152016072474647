import React, { useEffect, useState } from "react";
import konsole from "./control/Konsole";
import { $CommonServiceFn } from "./network/Service";
import { $Service_Url } from "./network/UrlPath";
import StarRating from "./StarRating";
import { useLocation } from "react-router-dom";
import { hideSideImageForUrlPaths } from "./control/Constant";

export function Layout ({ children, curMainImageNum }) {

    const [feeback, setFeedback]= useState([])
    const [currentIndex, setCurrentIndex] = useState(0);
    const [isNonLayoutPage, setisNonLayoutPage] = useState(false);
    const location = useLocation();

  useEffect(() => {
    const _isNonLayoutPage = typeof window != "undefined" ? (hideSideImageForUrlPaths?.includes(window?.location?.pathname?.toLowerCase())) : false;
    setisNonLayoutPage(_isNonLayoutPage);
  }, [location]);


  useEffect(()=>{
    console.log(children,"children, curMainImageNum",curMainImageNum)
  cusFeedback()
    },[])
   
    useEffect(() => {
        const interval = setInterval(() => {
          handleRight();
        }, 4000);
        return () => clearInterval(interval);
      }, [currentIndex]);

   const cusFeedback = () => {
    // setLoader(true);
    $CommonServiceFn.InvokeCommonApi("GET", $Service_Url.customerFeedback, "", (response, error) => {
      if (response) {
        setFeedback(response?.data.data)
        konsole.log(response,"feedbackresponse")
      }else{
        konsole.log(error,"errror")
      }
    })
  }

  const handleLeft = ()=>{
    setCurrentIndex((prevIndex) =>
        prevIndex === 0 ? feeback.length - 1 : prevIndex - 1
      );

  }
  const handleRight = ()=>{
    setCurrentIndex((prevIndex) =>
        prevIndex === feeback.length - 1 ? 0 : prevIndex + 1
      );
  }

  konsole.log(children, curMainImageNum ,"childrencurMainImageNum ")
  
  // const notShowLayOut = typeof window != "undefined" ? (window?.location?.pathname === "/Account/Redirect") : false;
  // konsole.log("adckjdb0", typeof window != "undefined" , notShowLayOut)

    return (isNonLayoutPage || curMainImageNum == 3)  ? <div id="mainBody">{children}</div>: <div id="mainBody"> 
    

    <div className="right">
      <div className={`feedbackMain ${curMainImageNum == 1 ? 'imageLogin' : 'imageSignUp'} `}> {/* this div have background img */}
        <div className="banner_P">
          <div className="banner_C ps-3">
            <div className="ringingBell">
              <img src="/icons/Bell.svg" alt="" />
            </div>
            <div>
              <h5 className="mb-0">Great News</h5>
              <p>Experience Our New User Interface</p>
            </div>
          </div>
        </div>
                   {/* {feeback?.map((ele,index)=>(
                  <div key={index} 
                   className={`feedbackShade ${index === currentIndex ? 'active' : ''}`}
                   style={{ display: index === currentIndex ? 'block' : 'none' }}
                  
                  >
                        <div className="feedbackBox">
                            <div className="row1">
                                <p>{ele.custFeed ? `"${ele.custFeed}"` : ""}</p>
                            </div>
                            <div className="row2">
                                <p>{ele.custName}</p>
                                <h6 className="custRole">{ele.custRole}</h6>
                                <div className="userStarts">
                                 <StarRating rating={ele.custFeedStar}/>
                                </div>
                            </div>
                            <div className="row3">
                                <div className="left">
                                    <p className="left_P">{ele.custRole}</p>
                                 
                                </div>
                                <div className="right">
                                    <div className="arrow" onClick={()=>handleLeft()}>
                                        <img className="white" src="/icons/leftArrow.svg" />
                                        <img className="black" src="/icons/leftArrowHover.svg" />
                                    </div>
                                    <div className="arrow " onClick={()=>handleRight()}>
                                        <img className="white" src="/icons/rightArrow.svg" /> 
                                        <img className="black" src="/icons/rightArrowHover.svg" />                       
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ))} */}
                </div>
            </div>
            <div className="left">
                {children}
            </div>
            </div>
}